import React from 'react'

function Home() {
    return (
        <div>
          <h1>Welcome to Chef Choice... </h1>  
        </div>
    )
}

export default Home
